<template>
  <div class="database-container">
    <div class="top-bar">
      <input
        type="text"
        v-model="searchInput"
        @keypress.enter="searchDatabase()"
      />
      <div
        :class="locationData ? 'location-marker-selected' : 'location-marker'"
        @click="toogleLocation()"
      >
        Location
      </div>
      <div
        :class="activityData ? 'activity-marker-selected' : 'activity-marker'"
        @click="toogleActivity()"
      >
        Activity
      </div>
      <button type="button" @click="searchDatabase()" class="search-button">
        Search
      </button>
      <button type="button" @click="addNewDatabaseEntry()" class="new-button">
        New
      </button>
      <button
        type="button"
        @click="uploadData"
        class="upload-button"
        v-show="$store.getters.userType != 'contactCenterAgent'"
      >
        Upload
      </button>
      <label
        for="fileUpload"
        class="browse-button"
        v-show="$store.getters.userType != 'contactCenterAgent'"
        >Browse</label
      >
      <input
        type="file"
        name="fileUpload"
        id="fileUpload"
        accept=".csv"
        @change="readParseCSV"
      />
    </div>
    <div class="results-container">
      <div
        class="upload-preview"
        v-show="uploadedData.length + newData.length > 0"
      >
        <DatabaseSingleDisplay
          v-for="previewentry in uploadedData"
          :key="previewentry.id"
          :information="previewentry"
          :editPossible="false"
        ></DatabaseSingleDisplay>
        <DatabaseSingleDisplay
          v-for="(newDatapreview, previewIndex) in newData"
          :key="previewIndex"
          :information="newDatapreview"
          :editPossible="true"
          @deleteEntry="newData.splice(previewIndex, 1)"
          @newInformation="changeData($event, previewIndex)"
        ></DatabaseSingleDisplay>
      </div>
      <hr v-if="uploadedData.length + newData.length > 0" />
      <div class="search-result" v-show="resultList.length > 0">
        <DatabaseSingleDisplay
          v-for="result in resultList"
          :key="result.id"
          :information="result"
          :editPossible="
            true && $store.getters.userType != 'contactCenterAgent'
          "
          @deleteEntry="searchDatabase"
        ></DatabaseSingleDisplay>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import DatabaseSingleDisplay from "./DatabaseSingleDisplay.vue";
import Papa from "papaparse";

export default {
  name: "Database",
  components: { DatabaseSingleDisplay },
  props: {
    location: { type: Boolean, default: false },
    activity: { type: Boolean, default: false },
    ticketId: String,
  },
  data() {
    return {
      searchInput: "",
      locationData: this.location,
      activityData: this.activity,
      resultList: [],
      uploadedData: [],
      newData: [],
    };
  },
  methods: {
    searchDatabase: async function () {
      try {
        if (this.searchInput.trim() != "") {
          this.$store.dispatch("load", "Requesting Data");
          let sentData = {
            location: this.locationData,
            activity: this.activityData,
          };
          if (this.$store.getters.userType == "contactCenterAgent") {
            sentData["agentId"] = this.$store.getters.userId;
          }
          if (this.ticketId) {
            sentData["ticketId"] = this.ticketId;
          }
          this.resultList = await HandleApi.searchTourismData(
            sentData,
            this.searchInput
          );
          this.resultList = this.resultList.data;
          this.$store.dispatch("unload");
        }
      } catch (err) {
        this.$store.dispatch("unload");
        window.console.log(err);
      }
    },
    toogleLocation: function () {
      if (this.locationData) {
        this.locationData = false;
      } else {
        this.locationData = true;
      }
    },
    toogleActivity: function () {
      if (this.activityData) {
        this.activityData = false;
      } else {
        this.activityData = true;
      }
    },
    readParseCSV(event) {
      let file = event.target.files[0];
      Papa.parse(file, {
        complete: (results) => {
          this.uploadedData = [];
          let temp = {};
          for (let i = 1; i < results.data.length; i++) {
            temp = {};
            for (let j = 0; j < results.data[0].length; j++) {
              temp[results.data[0][j]] = results.data[i][j];
            }
            this.uploadedData.push(temp);
          }
        },
      });
    },
    uploadData: async function () {
      try {
        this.$store.dispatch("load", "Uploading data");
        if (this.uploadedData.length > 0) {
          await HandleApi.uploadTourismInformation({
            tourismData: this.uploadedData,
          });
        }
        if (this.newData.length > 0) {
          await HandleApi.uploadTourismInformation({
            tourismData: this.newData,
          });
        }
        this.uploadedData = [];
        this.newData = [];

        this.$store.dispatch("unload");
      } catch (err) {
        this.$store.dispatch("unload");
        window.console.log(err);
      }
    },
    addNewDatabaseEntry: function () {
      this.newData.push({
        adress: "address",
        category: "General Information",
        city: "city",
        email: "email",
        for: "for",
        location: "location",
        name: "name",
        phone: "phone",
        practicalInformation: "practicalInformation",
        region: "region",
        remark: "remark",
        type: "type",
        edit: true,
        deploy: "v",
        showDetail: true,
      });
    },
    changeData(event, index) {
      this.newData.splice(index, 1, event);
    },
  },
  computed: {},
};
</script>

<style>
.database-container {
  margin-left: 10px;
  margin-right: 10px;
}

.top-bar input {
  width: 30%;
}

.location-marker {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 3px solid lightgrey;
  font-weight: bold;
  border-radius: 10px;
}

.location-marker:hover {
  cursor: pointer;
}

.activity-marker {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 3px solid lightgrey;
  font-weight: bold;
  border-radius: 10px;
}

.activity-marker {
  cursor: pointer;
}

.location-marker-selected {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 3px solid red;
  font-weight: bold;
  border-radius: 10px;
  color: red;
}

.location-marker:hover {
  cursor: pointer;
}

.activity-marker-selected {
  display: inline-block;
  padding: 5px;
  margin: 5px;
  background-color: lightgrey;
  border: 3px solid red;
  font-weight: bold;
  border-radius: 10px;
  color: red;
}

.activity-marker {
  cursor: pointer;
}

input[type="file"] {
  display: none;
}

.search-button {
  margin: 5px;
  border: 3px solid #008467;
  background-color: #008467;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
}

.search-button:hover {
  border-color: #2b3f33;
  color: #2b3f33;
}

.new-button {
  margin: 5px;
  border: 3px solid #00909f;
  background-color: #00909f;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
}

.new-button:hover {
  border-color: #034c57;
  color: #034c57;
}

.browse-button {
  margin: 5px;
  border: 3px solid #009fbc;
  background-color: #009fbc;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  float: right;
}

.browse-button:hover {
  border-color: #192256;
  color: #192256;
}

.upload-button {
  margin: 5px;
  border: 3px solid #aa1853;
  background-color: #aa1853;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  float: right;
}

.upload-button:hover {
  border-color: #40223c;
  color: #40223c;
}

.database-container hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
