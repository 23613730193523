<template>
  <div
    class="big-container"
    v-if="llocation && name && category && practicalInformation"
  >
    <div
      class="entry-header"
      @click="
        showDetail = !showDetail;
        deploy == '>' ? (deploy = 'v') : (deploy = '>');
      "
    >
      <button class="entry-deploy">
        {{ deploy }}
      </button>
      <span class="entry-name">{{ name }}</span>
    </div>
    <div class="entry-content" v-show="showDetail && !edit">
      <button
        type="button"
        @click="edit = true"
        v-show="editPossible"
        class="entry-edit-button"
      >
        edit
      </button>
      <button
        type="button"
        v-show="editPossible"
        @click="deleteEntry()"
        class="entry-delete-button"
      >
        delete
      </button>
      <hr />
      <div class="entry-text-container">
        <label for="name-input" v-if="name">Name:</label>
        <p class="entry-text" v-if="name">{{ name }}</p>
        <label for="name-input" v-if="llocation">Location:</label>
        <p class="entry-text" v-if="llocation">{{ llocation }}</p>
        <label for="name-input" v-if="region">Region:</label>
        <p class="entry-text" v-if="region">{{ region }}</p>
        <label for="name-input" v-if="city">City:</label>
        <p class="entry-text" v-if="city">{{ city }}</p>
        <label for="name-input" v-if="category">Category:</label>
        <p class="entry-text" v-if="category">{{ category }}</p>
        <label for="name-input" v-if="type">Type:</label>
        <p class="entry-text" v-if="type">{{ type }}</p>
        <label for="name-input" v-if="target">For:</label>
        <p class="entry-text" v-if="target">{{ target }}</p>
        <label for="name-input" v-if="adress">Adress:</label>
        <p class="entry-text" v-if="adress">{{ adress }}</p>
        <label for="name-input" v-if="email">Email:</label>
        <p class="entry-text" v-if="email">{{ email }}</p>
        <label for="name-input" v-if="phone">Phone:</label>
        <p class="entry-text" v-if="phone">{{ phone }}</p>
        <label for="name-input" v-if="practicalInformation"
          >Practical Information:</label
        >
        <p class="entry-text" v-if="practicalInformation">
          {{ practicalInformation }}
        </p>
        <label for="name-input" v-if="remark">Remark:</label>
        <p class="entry-text" v-if="remark">{{ remark }}</p>
      </div>
    </div>
    <div class="edit-entry-content" v-show="showDetail && edit && editPossible">
      <button type="button" @click="cancelEdit()" class="entry-cancel-button">
        cancel
      </button>
      <button type="button" @click="saveData()" class="entry-save-button">
        save
      </button>

      <hr />
      <div class="edit-entry-text-container">
        <label for="name-input">Name</label>
        <input class="edit-entry-text" v-model="name" />
        <label for="name-input">Location</label>
        <input class="edit-entry-text" v-model="llocation" />
        <label for="name-input">Region</label>
        <input class="edit-entry-text" v-model="region" />
        <label for="name-input">City</label>
        <input class="edit-entry-text" v-model="city" />
        <label for="name-input">Category</label>
        <select class="edit-entry-input" v-model="category">
          <option value="General Information">General Information</option>
          <option value="Accomodation">Accomodation</option>
          <option value="Transportation">Transportation</option>
          <option value="Restauration">Restauration</option>
          <option value="Cultural Activity">Cultural Activity</option>
          <option value="Sport Activity">Sport Activity</option>
          <option value="Outdoor Activity">Outdoor Activity</option>
          <option value="Relaxation Activity">Relaxation Activity</option>
          <option value="Volunteering Activity">Volunteering Activity</option>
        </select>

        <label for="name-input">Type</label>
        <input class="edit-entry-text" v-model="type" />
        <label for="name-input">Target</label>
        <input class="edit-entry-text" v-model="target" />
        <label for="name-input">Adress</label>
        <input class="edit-entry-text" v-model="adress" />
        <label for="name-input">Email</label>
        <input class="edit-entry-text" v-model="email" />
        <label for="name-input">Phone</label>
        <input class="edit-entry-text" v-model="phone" />
        <label for="name-input">Practical Information</label>
        <input class="edit-entry-text" v-model="practicalInformation" />
        <label for="name-input">Remark</label>
        <input class="edit-entry-text" v-model="remark" />
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "DatabaseSingleDisplay",
  props: {
    information: { type: Object, required: true },
    editPossible: { type: Boolean, required: true },
  },
  data() {
    return {
      id: this.information._id,
      llocation: this.information.location,
      region: this.information.region,
      city: this.information.city,
      category: this.information.category,
      type: this.information.type,
      target: this.information.for,
      practicalInformation: this.information.practicalInformation,
      name: this.information.name,
      email: this.information.email,
      phone: this.information.phone,
      adress: this.information.adress,
      remark: this.information.remark,
      showDetail: this.information.showDetail || false,
      deploy: this.information.deploy || ">",
      edit: this.information.edit || false,
    };
  },
  methods: {
    cancelEdit: function () {
      this.edit = false;
      this.id = this.information._id;
      this.llocation = this.information.location;
      this.region = this.information.region;
      this.city = this.information.city;
      this.category = this.information.category;
      this.type = this.information.type;
      this.target = this.information.for;
      this.practicalInformation = this.information.practicalInformation;
      this.name = this.information.name;
      this.email = this.information.email;
      this.phone = this.information.phone;
      this.adress = this.information.adress;
      this.remark = this.information.remark;
    },
    saveData: async function () {
      try {
        if (this.id) {
          this.$store.dispatch("load", "Saving Data");
          await HandleApi.putTourismData(this.id, {
            location: this.llocation,
            region: this.region,
            city: this.city,
            category: this.category,
            type: this.type,
            for: this.target,
            practicalInformation: this.practicalInformation,
            name: this.name,
            email: this.email,
            phone: this.phone,
            adress: this.adress,
            remark: this.remark,
          });
        } else {
          this.$emit("newInformation", {
            location: this.llocation,
            region: this.region,
            city: this.city,
            category: this.category,
            type: this.type,
            for: this.target,
            practicalInformation: this.practicalInformation,
            name: this.name,
            email: this.email,
            phone: this.phone,
            adress: this.adress,
            remark: this.remark,
            edit: false,
            deploy: "v",
            showDetail: true,
          });
        }
        this.$store.dispatch("unload");
      } catch (err) {
        this.$store.dispatch("unload");
        window.console.log(err);
      }
    },
    deleteEntry: async function () {
      try {
        if (this.id) {
          this.$store.dispatch("load", "Saving Data");
          await HandleApi.deleteTourismData(this.id);
        }
        this.$emit("deleteEntry");
        this.$store.dispatch("unload");
      } catch (err) {
        this.$store.dispatch("unload");
        window.console.log(err);
      }
    },
  },
  watch: {
    information: {
      handler(val) {
        this.id = val._id;
        this.llocation = val.location;
        this.region = val.region;
        this.city = val.city;
        this.category = val.category;
        this.type = val.type;
        this.target = val.for;
        this.practicalInformation = val.practicalInformation;
        this.name = val.name;
        this.email = val.email;
        this.phone = val.phone;
        this.adress = val.adress;
        this.remark = val.remark;
        this.showDetail = false;
        this.deploy = ">";
        this.edit = false;
      },
      deep: true,
    },
  },
};
</script>

<style>
.entry-header {
  height: 60px;
  line-height: 60px;
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(245, 245, 245, 1) 100%
  );
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.entry-deploy {
  margin-right: 10px;
  font-size: 20px;
  margin-left: 10px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  width: 30px;
}

.entry-header:hover {
  cursor: pointer;
}

.entry-name {
  font-size: 20px;
}

hr {
  margin-top: 0;
}

.entry-edit-button {
  margin: 10px;
  border: 3px solid #009fbc;
  background-color: #009fbc;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
}

.entry-edit-button:hover {
  border-color: #192256;
  color: #192256;
}

.entry-delete-button {
  margin: 10px;
  border: 3px solid #aa1853;
  background-color: #aa1853;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  float: right;
}

.entry-delete-button:hover {
  border-color: #40223c;
  color: #40223c;
}

.entry-save-button {
  margin: 10px;
  border: 3px solid #008467;
  background-color: #008467;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
  float: right;
}

.entry-save-button:hover {
  border-color: #2b3f33;
  color: #2b3f33;
}

.entry-cancel-button {
  margin: 10px;
  border: 3px solid #aa1853;
  background-color: #aa1853;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 10px;
}

.entry-cancel-button:hover {
  border-color: #40223c;
  color: #40223c;
}

input {
  margin-left: 10px;
}

select {
  margin-left: 10px;
}

.edit-entry-text-container {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 3fr;
  align-content: center;
  justify-content: middle;
  text-align: center;
  grid-row-gap: 5px;
}

.entry-text-container {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr 3fr;
  align-content: center;
  justify-content: middle;
  text-align: center;
  grid-row-gap: 5px;
}

.entry-text-container p {
  text-align: left;
  margin: 0 5px;
  padding-left: 5px;
  border: 1px solid black;
}

.entry-text-container label {
  border: 1px solid black;
}
</style>
